<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 offset-none col-sm-6 offset-sm-3 col-md-4 offset-md-4">
        <div class="d-flex align-items-center login-form-container">
          <div class="w-100">
            <div class="text-center mb-4">
              <h3 class="login-form-header">Forget Password</h3>
            </div>
            <b-alert variant="danger" :show="errorMessage != ''">{{
              errorMessage
            }}</b-alert>
            <b-form @submit="onSubmit">
              <b-form-group
                id="input-group-email"
                label="Please enter your email:"
                label-for="input-email"
              >
                <b-form-input
                  id="input-email"
                  v-model="form.email"
                  placeholder="Your email"
                  required
                  type="email"
                ></b-form-input>
              </b-form-group>

              <b-button type="submit" variant="primary" block>Submit</b-button>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
let utils = require("@/assets/global/js/utils.js");
import AjaxFetch from "@/assets/global/js/AjaxFetch.js";

export default {
  name: "ForgotPasswordForm",
  data() {
    return {
      form: {
        email: ""
      },
      errorMessage: ""
    };
  },
  computed: {
    ...mapGetters({
      getAPIServerURL: "session/getAPIServerURL"
    })
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      // var form_data = new URLSearchParams(this.form);
      var form_data = this.form;

      this.goToEmail(form_data);
    },
    goToEmail(form_data) {
      var $this = this;

      var API_URL = $this.getAPIServerURL + "/auth/forgot-password/";

      const client = new AjaxFetch(null, this);

      client
        .postRequest(API_URL, form_data)
        .then((data) => {
          if (data == null) {
            $this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text:
                "Please check your email. We have sent you an email with instructions to reset your password.",
              message_type: "success"
            });
            $this.$router.push({ name: "login" }).catch(() => {});
          } else {
            $this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: data.detail,
              message_type: "danger"
            });
          }
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    }
  }
};
</script>

<style lang="scss"></style>
